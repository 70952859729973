.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: var(--font-3xl);
  color: var(--deep-red);
}

.subheading {
  font-size: var(--font-lg);
  font-weight: var(--font-weight-medium);
  color: var(--text-dark-orange);
}
