.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: var(--br-xs);
  transition: all 0.3s linear;
  position: relative;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px;
}

.root {
  background-color: white;
}

.ctaWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.root:hover {
  transform: scale(1.01);
}

.title {
  font-size: var(--font-xl);
  font-weight: var(--font-weight-midBold);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jobs-plug-title {
  font-size: var(--font-md);
}

.cardImg {
  border-radius: var(--br-xxs);
  object-fit: cover;
}

.skillTag,
.includedContentPill {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: var(--font-xs);
  padding: 5px 10px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
}

.skillTag {
  color: var(--theme-blue);
  background-color: var(--bg-light-blue);
}

.cardDetailsWrapper {
  display: flex;
  gap: 12px;
  width: 100%;
}

.upskill,
.jobs-plug-upskill {
  width: 100%;
  background-color: var(--yellow);
  padding: 8px 28px;
  border-radius: var(--br-xxs);
  font-weight: var(--font-weight-medium);
  text-align: center;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.jobs-plug-upskill {
  padding: 8px 18px;
}

.buttonWrapper {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.uspTag {
  padding: 8px 16px;
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-sm);
  position: relative;
  border-radius: 0 0 var(--br-xs) var(--br-xs);
}

@media (max-width: 500px) {
  .uspTag {
    text-align: center;
  }

  .cardImg {
    width: 100%;
  }

  .buttonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .cardDetailsWrapper {
    flex-direction: column;
    gap: 6px;
  }

  .upskill {
    padding: 10px 28px;
  }
}

.premiumTag {
  position: absolute;
  top: 18px;
  left: -44px;
  z-index: 1;
  width: 150px;
  padding: 4px 0;
  transform: rotate(-45deg);
  font-size: var(--font-sm);
  font-weight: var(--font-weight-semiBold);
  background-color: var(--red);
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ctaButton {
  height: fit-content;
  padding: 10px 24px;
}

.featured {
  border: 2px solid var(--yellow);
  background-color: var(--bg-light-yellow);
}

.type {
  font-size: var(--font-xs);
  color: var(--text-darker-grey);
  font-weight: var(--font-weight-medium);
  padding: 5px 5px 5px 0;
}

.iconText {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: none;
}

.assessment-pill {
  background-color: var(--bg-light-orange);
  color: var(--text-dark-orange);
}

.assessment-root {
  border: 1.4px solid var(--text-dark-orange);
}

.PASS-assessment-root {
  border: 2px solid var(--dark-text-green);
}

.FAIL-assessment-root {
  border: 2px solid var(--deep-red);
}

.PASS-assessment-root:hover {
  box-shadow: var(--dark-text-green) 0px 2px 8px 0px;
}

.FAIL-assessment-root:hover {
  box-shadow: var(--deep-red) 0px 2px 8px 0px;
}

.imageWrapper {
  position: relative;
  height: 124px;
}

.tagText-FAIL,
.tagText-PASS {
  padding: 4px 8px;
  position: absolute;
  right: 0;
  border-radius: 0 var(--br-xxxs) 0 var(--br-xxxs);
  font-size: var(--font-xs);
  font-weight: var(--font-weight-medium);
}

.tagText-PASS {
  color: var(--dark-text-green);
  background-color: var(--light-bg-green);
}

.tagText-FAIL {
  color: var(--deep-red);
  background-color: var(--bg-light-red);
}

.scoreCol {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-end;
  flex: none;
  justify-content: center;
}

.FAIL-score,
.PASS-score {
  font-size: var(--font-md);
  font-weight: var(--font-weight-medium);
}

.FAIL-jobs-plug-score,
.PASS-jobs-plug-score {
  font-size: var(--font-xs);
  font-weight: var(--font-weight-medium);
}

.FAIL-score,
.FAIL-jobs-plug-score {
  color: var(--deep-red);
}

.PASS-score,
.PASS-jobs-plug-score {
  color: var(--dark-text-green);
}

@media (min-width: 500px) {
  .uspTag {
    position: absolute;
    top: -2px;
    right: -2px;
    border-radius: 0 var(--br-xxs) 0 var(--br-xs);
  }
}

@media (max-width: 500px) {
  .scoreCol {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
  }

  .ctaWrapper {
    width: 100%;
  }

  .buttonWrapper {
    gap: 0px;
  }

  .FAIL-score,
  .PASS-score {
    font-size: var(--font-xl);
  }
}
