.pillsContainer {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.pill {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 2px;
  border-radius: var(--br-xxxs);
  font-size: var(--font-xs);
  font-weight: var(--font-weight-semiBold);
}
