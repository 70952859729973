.root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;
  position: relative;
  align-items: "center";
  justify-content: "space-between";
  width: "100%";
}

.pointer {
  cursor: pointer;
}

.title {
  font-size: var(--font-md);
  font-weight: var(--font-weight-midBold);
}

.cardImg {
  object-fit: cover;
  object-position: top;
  flex: none;
}

.featureTags {
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  font-size: var(--font-xs);
  color: var(--text-darker-grey);
}

.cardDetailsWrapper {
  display: flex;
  gap: 12px;
  width: 100%;
  align-items: flex-start;
}

.unlockedText {
  font-weight: var(--font-weight-medium);
  font-size: var(--font-sm);
  line-height: 1.2;
  position: relative;
  border-radius: var(--br-xxs);
  padding: 4px 8px;
  color: var(--canva-purple);
  background-color: var(--bg-light-purple);
  border: 1px solid var(--canva-purple);
  margin: auto;
}

.separator {
  padding: 0 1px;
}

.body {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.imgWrapper {
  position: relative;
  height: 96px;
}

.imgTagWrapper {
  padding: 6px;
  font-weight: var(--font-weight-semiBold);
  font-size: var(--font-xs);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;

  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );
}

@media (max-width: 500px) {
  .description {
    display: none !important;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .unlockedText {
    display: none;
  }
  .cardImg {
    width: 96px;
    height: 96px;
  }
}

@media (min-width: 500px) {
  .title {
    font-size: var(--font-md);
  }
}

.type {
  font-size: var(--font-xs);
  color: var(--text-darker-grey);
  font-weight: var(--font-weight-medium);
  padding: 5px 5px 5px 0;
}

.description {
  font-size: var(--font-sm);
  color: var(--black);
  margin: 4px 0 0 0;
}

.trunc-1,
.trunc-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.trunc-1 {
  -webkit-line-clamp: 1;
}

.trunc-2 {
  -webkit-line-clamp: 2;
}

.iconText {
  display: flex;
  align-items: center;
  gap: 4px;
  flex: none;
}

.-pill {
  color: var(--theme-blue);
  background-color: var(--bg-light-blue);
}
